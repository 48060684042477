
import translationEN from "./locales/en.json";
import translationFr from "./locales/fr.json";
import translationDe from "./locales/de.json";
import translationEs from "./locales/es.json";
import translationHi from "./locales/hi.json";

export const resources = {
    en: {
      translation: translationEN,
    },
    fr: {
      translation: translationFr,
    },
    de: {
      translation: translationDe,
    },
    es: {
      translation: translationEs,
    },
    hi: {
      translation: translationHi,
    },
  };