/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useContext, useState, useEffect, ReactNode } from "react";
interface AppConfigContextType {
    appName: string;
  }
  
  interface AppConfigProviderProps {
    children: ReactNode; // Typing the children prop as ReactNode
  }
  
  const AppConfigContext = createContext<AppConfigContextType | undefined>(undefined);
  
  export const useAppConfig = (): AppConfigContextType => {
    const context = useContext(AppConfigContext);
    if (!context) {
      throw new Error('useAppConfig must be used within an AppConfigProvider');
    }
    return context;
  };

  export const AppConfigProvider: React.FC<AppConfigProviderProps> = ({ children }) => {
  const [appName, setAppName] = useState<string>("");
  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const baseUrl = process.env.REACT_APP_BASE_URL;
        const response = await fetch(`${baseUrl}/getSettings`); // Replace with your API URL
        const data = await response.json();
        if (data.success) {
          if (data?.data && data?.data.length > 0) {
            localStorage.setItem("appSetting", JSON.stringify(data?.data[0]));
            // Update meta description
            const metaDescription = document.querySelector(
              "meta[name='description']"
            );
            if (metaDescription) {
              metaDescription.setAttribute(
                "content",
                data?.data[0].website_description
              );
            } else {
              const newMeta = document.createElement("meta");
              newMeta.name = "description";
              newMeta.content = data?.data[0].website_description;
              document.head.appendChild(newMeta);
            }
            const newAppName = data?.data[0]?.website_name;
            setAppName(newAppName);
            const linkFavicon = document.querySelector("link[rel='icon']");
            if (linkFavicon) {
              (linkFavicon as HTMLLinkElement).href =
                process.env.REACT_APP_URL_FOR_AUDIO +
                data?.data[0].favicon_logo;
            } else {
              const newFavicon = document.createElement("link");
              newFavicon.rel = "icon";
              newFavicon.href =
                process.env.REACT_APP_URL_FOR_AUDIO +
                data?.data[0].favicon_logo;
              document.head.appendChild(newFavicon);
            }
          }
        } else {
            localStorage.setItem("appSetting", JSON.stringify({}));
        }
      } catch (err) {
        localStorage.setItem("appSetting", JSON.stringify({}));
      }
    };

    fetchConfig();
  }, []);

  return (
    <AppConfigContext.Provider value={{ appName }}>
      {children}
    </AppConfigContext.Provider>
  );
};
