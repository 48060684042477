import ReactDOM from "react-dom/client";
import "./index.css";
import { ChakraProvider, theme } from "@chakra-ui/react";
import App from "./App";
import { GTMProvider } from "@elgorditosalsero/react-gtm-hook";
import { AppConfigProvider } from "AppConfigProvider";
import initializeI18n from "./i18nInit";
import "react-vertical-timeline-component/style.min.css";
import "react-quill/dist/quill.snow.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Initialize i18n
initializeI18n();

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <GTMProvider
    state={{
      id: "GTM-MQV7HP82",
    }}
  >
    <ChakraProvider theme={theme}>
      <AppConfigProvider>
        <App />
      </AppConfigProvider>
    </ChakraProvider>
  </GTMProvider>
);
