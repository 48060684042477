// i18nInit.ts
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { resources } from "./resources"; 

const initializeI18n = () => {
  const storedLanguage = localStorage.getItem("language");
  const browserLanguage = navigator.language.split("-")[0];
  const supportedLanguages = ["en", "es", "fr", "de"];

  if (storedLanguage && supportedLanguages.includes(storedLanguage)) {
    i18n.use(initReactI18next).init({
      resources,
      lng: storedLanguage,
      interpolation: { escapeValue: false },
    });
  } else if (supportedLanguages.includes(browserLanguage)) {
    i18n.use(initReactI18next).init({
      resources,
      lng: browserLanguage,
      interpolation: { escapeValue: false },
    });
    localStorage.setItem("language", browserLanguage);
  } else {
    i18n.use(initReactI18next).init({
      resources,
      lng: "en",
      interpolation: { escapeValue: false },
    });
  }
};

export default initializeI18n;