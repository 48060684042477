/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable max-len */
import React from "react";

import type { ConfirmOptions } from "./confirm.types";
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";

type Props = ConfirmOptions & { open: boolean };

function WarningIcon() {
  return (
    <svg
      fill="none"
      height="24"
      viewBox="0 0 36 36"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 9.735L29.295 29.25H6.705L18 9.735ZM18 3.75L1.5 32.25H34.5L18 3.75Z"
        fill="#ED6C02"
      />
      <path d="M19.5 24.75H16.5V27.75H19.5V24.75Z" fill="#ED6C02" />
      <path d="M19.5 15.75H16.5V23.25H19.5V15.75Z" fill="#ED6C02" />
    </svg>
  );
}

export function Confirm({
  open,
  title,
  subtitle,
  cancelText,
  confirmText,
  onCancel,
  onConfirm,
  type = "warning",
}: Props) {
  return (
    <>
      <Modal
        isCentered
        isOpen={open}
        onClose={onCancel}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <div className="flex items-center gap-2">
              <WarningIcon /> {title}
            </div>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <div className="mb-5 mt-5 text-center">{subtitle}</div>
          </ModalBody>
          <ModalFooter display={"flex"} justifyContent={"center"} gap={"10px"}>
            <Button
              onClick={onCancel}
              colorScheme="blue"
              className="dark:bg-blue-700 dark:text-white"
            >
              {cancelText}
            </Button>
            <Button
              onClick={onConfirm}
              colorScheme="red"
              className="dark:bg-red-500-700 dark:text-white"
            >
              {confirmText}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
